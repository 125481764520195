import headshot from "../assets/images/headshot.png";

function About() {
  return (
    <div>
      <h6 className="back-home-button">
        <a className="partial-underline-sm" href="/">
          Home
        </a>
      </h6>
      <div className="screen-swipe"></div>
      <div className="about-page-container">
        <h1 className="fade-in">About</h1>
        <div className="about-content">
          <div className="row gx-5">
            <div className="col-lg-6 order-lg-2 mb-5 fade-in">
              <img
                className="headshot"
                src={headshot}
                alt="Headshot of Jorden Gerovac"
              />
            </div>
            <div className="col-lg-6 order-lg-1 mb-4 fade-in">
              <p className="subtle">
                I'm <span className="pure-white">Jorden Gerovac</span>, a{" "}
                <span className="pure-white">
                  Full Stack Software Developer
                </span>{" "}
                with <span className="pure-white">5+</span> years of experience
                building modern web applications. I'm passionate about building
                efficient, scalable software solutions and enjoy using the
                latest frameworks and technologies to create intuitive and
                impactful applications. Currently, I work at Veriday, where I
                primarily use <span className="pure-white">Spring Boot</span>,{" "}
                <span className="pure-white">React</span>, and{" "}
                <span className="pure-white">Docker</span> to develop
                high-quality digital solutions.
              </p>
              <p className="subtle">
                Throughout my career I've had the opportunity to build unique
                software for a variety of companies in a variety of
                industries—from vendor portals in the{" "}
                <span className="pure-white">retail</span> industry to employee
                intranets in the <span className="pure-white">banking</span>{" "}
                industry. No matter the project, I'm always eager to learn and
                stay ahead of industry trends, constantly exploring new tools
                and methodologies to improve my craft.
              </p>
              <p className="subtle">
                For me, technology is more than just a career—it's a lifelong
                passion. I thrive on challenges that push me to grow, whether
                it's debugging a tricky issue or designing innovative systems. I
                also value collaboration and believe that the best ideas come
                from working closely with others in an environment where
                creativity and teamwork can flourish.
              </p>
              <p className="subtle">
                I'm always striving to deliver meaningful solutions while
                inspiring others around me to embrace growth, innovation, and
                continuous learning.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
